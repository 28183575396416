import { Tooltip } from "bootstrap";

export var formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
});

export var formatCurrencyNoDecimal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
});


export function formatWithCommas(value) {
    let updatedValue = formatCurrency.format(value.toString().replace(/\s*,\s*|\s+,/g, ''));
    return updatedValue.replace("$", '');
}

export function formatToNumber(value) {
    let updatedValue = value.toString().replace(/\s*,\s*|\s+,/g, '');
    return Number(updatedValue);
}

export function formatNumber(numToFormat) {
    let formattedNum = (numToFormat < 0) ? 0 : Number(numToFormat.toFixed(6));
    return formattedNum;
}

export function formatNumberExact(numToFormat) {
    let formattedNum = (numToFormat < 0) ? 0 : Number(numToFormat.toFixed(13));
    return formattedNum;
}

export function formatPercent(numToFormat) {
    let formattedNum = (numToFormat / 100);
    return formattedNum;
}

export function getTooltipValue(toolTipText, audienceType, tooltipID) {
    let valueType = audienceType.toLowerCase() + "Value";
    return toolTipText.find(helpText => helpText.id == tooltipID)[valueType];
}

export function getInputValue(inputsText, audienceType, tooltipID) {
    let valueType = "is" + audienceType;
    return inputsText.find(helpText => helpText.id == tooltipID)[valueType];
}

export function getInputName(inputsText, audienceType, tooltipID) {
    let valueType = audienceType.toLowerCase() + "Value";
    return inputsText.find(helpText => helpText.id == tooltipID)[valueType];
}