import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { formatCurrencyNoDecimal, getTooltipValue } from '../utils/Format'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactTooltip from 'react-tooltip';
import { HelpTextHover } from './Tooltip';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <ul>
                    <li><span className="tooltipLabel">Age</span>{label}</li>
                    <li><span className="tooltipLabel">401(k)</span>{formatCurrencyNoDecimal.format(payload[0].value)}</li>
                    <li><span className="tooltipLabel">ESOP</span>{formatCurrencyNoDecimal.format(payload[1].value)}</li>
                    <li><span className="tooltipLabel">Total</span>{formatCurrencyNoDecimal.format(payload[0].value + payload[1].value)}</li>
                </ul>
            </div>
        );
    }

    return null;
};

export class Graph extends Component {

    render() {
        let audienceType = this.props.audience;
        let helpTextData = this.props.helpTextData;

        let TotalESOPTooltipText = getTooltipValue(helpTextData, audienceType, 4);
        let Total401TooltipText = getTooltipValue(helpTextData, audienceType, 3);
        let TotalFundsTooltipText = getTooltipValue(helpTextData, audienceType, 5);
        
        return (
            <>
                <Container>
                    <Row>
                        <Col xs={12} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                            <Row style={{ marginBottom: "20px", marginTop: "20px" }} >
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <Row>
                                        <Col xs={12}>
                                            <span className='displayTotalLabel'>401(k)
                                                <a data-tip data-for="total401"><HelpTextHover /></a>
                                                <ReactTooltip actTooltip id="total401" aria-haspopup="true">{Total401TooltipText}</ReactTooltip>
                                            </span>
                                        </Col>
                                        <Col xs={12}>
                                            <span className='display401Total'>
                                                {formatCurrencyNoDecimal.format(this.props.retirementTotal)}
                                            </span>
                                        </Col>

                                    </Row>
                                </Col>

                                <Col className='d-flex align-items-center justify-content-center'>
                                    <Row>
                                        <Col xs={12}>
                                            <span className='displayTotalLabel'>ESOP
                                                <a data-tip data-for="totalESOP"><HelpTextHover /></a>
                                                <ReactTooltip actTooltip id="totalESOP" aria-haspopup="true">{TotalESOPTooltipText}</ReactTooltip>
                                            </span>
                                        </Col>
                                        <Col xs={12}>
                                            <span className='displayESOPTotal'>
                                                {formatCurrencyNoDecimal.format(this.props.ESOPTotal)}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className='d-flex align-items-center justify-content-center'>
                                    <Row>
                                        <Col xs={12}>
                                            <span className='displayTotalLabel labelTotalFunds'>Total
                                                <a data-tip data-for="totalFunds"><HelpTextHover /></a>
                                                <ReactTooltip actTooltip id="totalFunds" aria-haspopup="true">{TotalFundsTooltipText}</ReactTooltip>
                                            </span>
                                        </Col>
                                        <Col xs={12}>
                                            <span className='displayFundsTotal' style={{ fontWeight: "500" }}>
                                                {formatCurrencyNoDecimal.format(this.props.fundsTotal)}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="d-xs-block" >
                            <div style={{ maxWidth: "450px" }}>
                                <BarChart
                                    height={600} width={750}
                                    margin={{ top: 0, bottom: 0, left: 80, right: 80 }}
                                    data={this.props.chartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="age" label={{ value: 'Age', position: 'insideBottom', dy: 20 }} />
                                    <YAxis tickFormatter={(value) => formatCurrencyNoDecimal.format(value)} label={{ value: 'Dollars', angle: -90, position: 'insideLeft', dx: -70, dy: -20 }} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend wrapperStyle={{ top: 25 }} layout="horizontal" verticalAlign="bottom" align="center" />
                                    <Bar name="401(k)" dataKey="Retire" stackId="a" fill="#05D1F5" />
                                    <Bar dataKey="ESOP" stackId="a" fill="#6CD272" />
                                </BarChart>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
    
}
