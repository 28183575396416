import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HelpText from '../components/HelpText';
import Defaults from '../components/Defaults';
import StaticValues from '../components/StaticValues';
import InputsAdmin from '../components/InputsAdmin';


export default function Admin({ username }) {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        setIsAdmin(true);
    })

    return (
        <Container>
            {isAdmin && <Row>
                <Col md="6">
                    <StaticValues username={username} />
                    <HelpText username={username} />
                </Col>
                <Col md="6">
                    <Defaults username={username} />
                    <InputsAdmin username={username} />
                </Col>
            </Row>}
            {!isAdmin && <div>Access denied. Please contact your administrator for assistance.</div> }
        </Container>
    );
}