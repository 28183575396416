import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom"; import App from './App';
import config from "./././"
//MSAL imports
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(msalConfig);

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

//get the current URL to know whether internal or external access
const sender = window.location.hostname;

if (sender == "aspire-candidate.burnsmcd.com") {
    //get the referrer
    const referrer = document.referrer;
    console.log("Referrer : " + referrer);
    if (referrer.startsWith("https://careers.burnsmcd.com/welcome-to-employee-ownership") || referrer.startsWith("https://info.burnsmcd.com/welcome-to-employee-ownership-us")) {
        root.render(
            <Router>
                <App audience="External" />
            </Router>);
    } else {
        root.render(
            <Router>
                <App audience="Unauthorized" />
            </Router>);
    }
} else {
    root.render(
        <Router>
            <App pca={msalInstance} audience="Internal" />
        </Router>);
}


