import { InfoCircle } from 'react-bootstrap-icons';

//export const CurrentCompTooltipText = 'Enter total cash compensation (base salary + bonus). This is automatically capped at the annual IRS compensation limit applicable for benefit plan purposes.';
//export const CompRateTooltipText = 'Considering your advancement potential, enter your annual growth rate in total cash compensation (base salary + bonus).';
//export const DeferralTooltipText = 'The dollar value is automatically capped at the annual IRS maximum.  To maximize the company matching contributions, assume an employee contribution rate of at least 6.5%.';
//export const CatchUpTooltipText = 'Upon attaining age 50, you are allowed to make annual catch-up contributions at any percentage of compensation.  The dollar value is automatically capped at the annual IRS maximum.';
//export const Current401TooltipText = 'For new employees, this will be 0 unless a rollover was made.';
//export const Est401ReturnTooltipText = 'Consider rates of return over long periods of time.';
//export const ShareIncreaseTooltipText = 'Increases in company stock value can vary widely based on company performance, and historical returns may not represent future results.  Consider using increases in the range of 5% to 15%.  Annual growth rates over a long period of time can have a significant compounding effect.';
//export const PercentIncreaseTooltipText = 'Increases in dividends can vary widely, and historical returns may not represent future results.  Consider using increases in the range of 5% to 15%.  Annual growth rates over a long period of time can have a significant compounding effect.';
//export const CurrentESOPTooltipText = 'For new employees, this will be 0.';
//export const EstESOPReturnTooltipText = 'Consider rates of return over long periods of time.';
//export const BeginningSharesTooltipText = 'For new employees, this will be 0.';
//export const Total401TooltipText = 'Forecasted 401(k) Account at Retirement';
//export const TotalESOPTooltipText = 'Forecasted ESOP Account at Retirement';
//export const TotalFundsTooltipText = 'Total Forecasted Funds at Retirement';

export const HelpTextHover = () => {
    return (
        <InfoCircle className="tooltipIcon" size={16} color="#625E5B" />
    );
};