import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Label, Input, Collapse, Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';


function EditHelpText(props) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    function closeModal() {
        setData(null);
        props.setModalItemID(null);
        props.setShowModal(false);
    }

    async function HandleSubmit(e) {
        e.preventDefault();

        const submitOptions = {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                internalValue: e.target.internal_value.value,
                externalValue: e.target.external_value.value,
                //modified: moment().format(),
                modifiedBy: props.username,
                sortOrder: e.target.sortOrder.value,
                //isInternal: e.target.is_internal.checked,
                //isExternal: e.target.is_external.checked,
                name: e.target.displayName.value
            })
        };

        fetch(`/api/HelpText/${props.itemID}`, submitOptions)
            .then((response) => {
                return response;
                console.log(response);
                setError(null);
            })
            .catch((err) => {
                setError(err.message);
            })
            .finally(() => {
                setData(null);
                props.setModalItemID(null);
                closeModal();
                props.getHelpTexts();
            });
    }

    useEffect(() => {
        if (props.itemID && !data) {
            fetch(`/api/HelpText/${props.itemID}`)
                .then((response) => {
                    return response.json();
                })
                .then((actualData) => {
                    setData(actualData);
                    setError(null);
                })
                .catch((err) => {
                    setError(err.message);
                    setData(null);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    });

    return (
        <Modal isOpen={props.showModal}>
            {loading && <div>Loading...</div>}
            {error && (
                <div>{`There is a problem fetching the post data - ${error}`}</div>
            )}
            {data &&
                (
                    <>
                        <ModalHeader>
                            {data.name}
                        </ModalHeader>
                        <ModalBody>
                            <Container>
                            <Form onSubmit={(evt) => HandleSubmit(evt)}>
                                <Row className="mb-2">
                                    <Col xs="4">
                                        <Label htmlFor="displayName">Display Name</Label>
                                    </Col>
                                    <Col xs="8">
                                        <Input required type="text" name="displayName" defaultValue={data.name} />
                                    </Col>
                                </Row>
                                {/*<Row className="mb-2">*/}
                                {/*    <Col xs="4">*/}
                                {/*        <Label htmlFor="internalValue">Visbility</Label>*/}
                                {/*    </Col>*/}
                                {/*    <Col xs="4" className="mt-1">*/}
                                {/*        <Input type="checkbox" name="is_internal" defaultChecked={data.isInternal} />*/}
                                {/*        <label className="lblDescription" htmlFor="is_internal">Internal</label>*/}
                                {/*    </Col>*/}
                                {/*    <Col xs="4" className="mt-1">*/}
                                {/*        <Input type="checkbox" name="is_external" defaultChecked={data.isExternal} />*/}
                                {/*        <label className="lblDescription" htmlFor="is_external">External</label>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                    <Row>
                                        <Col xs="12">
                                            <Label for="internalValue">Internal Value</Label>
                                        </Col>

                                    </Row>
                                <Row className="mb-2">
                                        <Col xs="12">
                                        <Input required rows="5" type="textarea" name="internal_value" defaultValue={data.internalValue} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12">
                                            <Label for="externalValue">External Value</Label>
                                        </Col>
                                    </Row>
                                <Row className="mb-3">
                                        <Col xs="12">
                                            <Input required rows="5" type="textarea" name="external_value" defaultValue={data.externalValue} />
                                        </Col>
                                </Row>
                                <Row>
                                    <Col xs="4">
                                        <Label for="sortorder">Sort Order</Label>
                                    </Col>
                                    <Col xs="8">
                                        <Input step="1" type="number" required name="sortOrder" defaultValue={data.sortOrder} />
                                        {/*<span className="form-text text-muted">Use 0 if there is no custom sort needed.</span>*/}
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col xs="12">
                                        <div className="modifiedByText">Last modified by {data.modifiedBy} on <Moment local format="MM/DD/YY hh:mm a">{data.modified}</Moment></div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <ModalFooter>
                                            <Button onClick={closeModal}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" type="Submit">
                                                Submit
                                            </Button>
                                        </ModalFooter>
                                    </Row>
                                </Form>
                            </Container>
                        </ModalBody>
                    </>
                )}

        </Modal>
    )
}

export default EditHelpText;
