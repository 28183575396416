//import { StartingDividendLevel, MaxContributionPercentage, MinimumCompLevel, IRSCompLimit, MaxAnnualContribution, MaxCompanyMatch, CompanyMatchSubjectToMatch, MaxCatchUpContribution, AssumedQualifiedCompensation, AssumedSharesAvailable, TotalAnnualCashContribution, InflationRateOfTotalQualifiedComp, InflationRateOfTotalAnnualCash, CurrentMarketValueOfShares } from '../constants/Global'
import { formatNumber, formatPercent, formatNumberExact, formatToNumber } from '../utils/Format';


export const computeData = (parentState) => {
    var currentAge = Number(parentState.currentAge);
    var retirementAge = Number(parentState.retirementAge);
    var yearsToRetirement = (retirementAge - currentAge) + 1;
    //var est401Return = formatPercent(parentState.est401Return);
    var est401Return = formatPercent(parentState.est401Return);
    var data = [];
    var calcAge = Number(parentState.currentAge);
    var calcTotalComp = formatToNumber(parentState.currentComp);
    var calcRetirementAmount = formatToNumber(parentState.current401);
    var calcESOPAmount = 0;
    var calcDividendLevel = parentState.StartingDividendLevel; //B32
    var calcMarketValueSharesInESOP = parentState.CurrentMarketValueOfShares;
    var calcTotalQualifiedComp = parentState.AssumedQualifiedCompensation;//B34
    var calcTotalCashContribution = parentState.TotalAnnualCashContribution;//B36
    var calcOpeningCashBalance = formatToNumber(parentState.openingCashBalance); //B42
    var calcEndingCashBalanceBeforeReturn = 0;
    var calcSharesHeldInESOP = parentState.beginningSharesHeld; //B51
    var providedCompRate = formatPercent(parentState.compRate);
    //var providedCompRate = parentState.compRate;
    var providedDeferralPercentage = formatPercent(parentState.deferralPercentage);
    //var providedDeferralPercentage = parentState.deferralPercentage;
    var providedAssumedPercentageIncreaseShares = formatPercent(parentState.assumedPercentageIncreaseShares); //B31
    //var providedAssumedPercentageIncreaseShares = parentState.assumedPercentageIncreaseShares; //B31
    var providedAssumedPercentageIncreaseDividends = formatPercent(parentState.assumedPercentageIncreaseDividends); //B33
    //var providedAssumedPercentageIncreaseDividends = parentState.assumedPercentageIncreaseDividends; //B33
    var providedAssumedPercentageIncreaseCash = formatPercent(parentState.assumedPercentageIncreaseCash); //B50
    //var providedAssumedPercentageIncreaseCash = parentState.assumedPercentageIncreaseCash; //B50
    var providedCatchUpAmount = formatToNumber(parentState.catchUpAmount);
    //var StartingDividendLevel = parentState.StartingDividendLevel;
    var MaxContributionPercentage = formatPercent(parentState.MaxContributionPercentage);
    var MinimumCompLevel = parentState.MinimumCompLevel;
    var IRSCompLimit = parentState.IRSCompLimit;
    var MaxAnnualContribution = parentState.MaxAnnualContribution;
    var MaxCompanyMatch = formatPercent(parentState.MaxCompanyMatch);
    var CompanyMatchSubjectToMatch = formatPercent(parentState.CompanyMatchSubjectToMatch);
    //var MaxCatchUpContribution = formatPercent(parentState.MaxCatchUpContribution);
    var MaxCatchUpContribution = parentState.MaxCatchUpContribution;
    //var AssumedQualifiedCompensation = parentState.AssumedQualifiedCompensation;
    var AssumedSharesAvailable = parentState.AssumedSharesAvailable;
    //var TotalAnnualCashContribution = parentState.TotalAnnualCashContribution;
    var InflationRateOfTotalQualifiedComp = formatPercent(parentState.InflationRateOfTotalQualifiedComp);
    var InflationRateOfTotalAnnualCash = formatPercent(parentState.InflationRateOfTotalAnnualCash);
    //var CurrentMarketValueOfShares = parentState.CurrentMarketValueOfShares;

    for (var i = 0; i < yearsToRetirement; i++) {
        //#region 401K
        var calcDeferralPercentage;
        if (providedDeferralPercentage > MaxContributionPercentage) {
            if (calcTotalComp > MinimumCompLevel) {
                calcDeferralPercentage = MaxContributionPercentage;
            } else {
                calcDeferralPercentage = providedDeferralPercentage;
            }
        } else {
            calcDeferralPercentage = providedDeferralPercentage;
        }

        var computed401Contribution;
        var compFor401 = (calcTotalComp < IRSCompLimit) ? calcTotalComp : IRSCompLimit;
        if ((compFor401 * calcDeferralPercentage) > MaxAnnualContribution) {
            computed401Contribution = MaxAnnualContribution
        } else {
            computed401Contribution = (compFor401 * calcDeferralPercentage);
        }

        var companyMatch;
        if (calcDeferralPercentage < MaxCompanyMatch) {
            companyMatch = (compFor401) * (calcDeferralPercentage * CompanyMatchSubjectToMatch);
        } else {
            companyMatch = (compFor401) * (MaxCompanyMatch * CompanyMatchSubjectToMatch);
        }

        var computedCatchUpContribution;
        if (calcAge > 49) {
            if (providedCatchUpAmount > MaxCatchUpContribution) {
                computedCatchUpContribution = MaxCatchUpContribution
            } else {
                computedCatchUpContribution = providedCatchUpAmount
            }
        } else {
            computedCatchUpContribution = 0;
        }

        var total401Contribution = (computed401Contribution + companyMatch + computedCatchUpContribution);
        var interestOnStartBalance = (calcRetirementAmount * est401Return);
        var interestOnCurrentContributions = ((total401Contribution * est401Return) / 3);
        calcRetirementAmount = (calcRetirementAmount + total401Contribution + interestOnStartBalance + interestOnCurrentContributions);
        //end region


        //#region ESOP
        var numberOfSharesAllocable;
        if (calcTotalComp > IRSCompLimit) {
            numberOfSharesAllocable = IRSCompLimit / calcTotalQualifiedComp * AssumedSharesAvailable;
        } else {
            numberOfSharesAllocable = calcTotalComp / calcTotalQualifiedComp * AssumedSharesAvailable;
        }
        numberOfSharesAllocable = formatNumberExact(numberOfSharesAllocable); //B38

        var valueOfSharesAllocable;
        valueOfSharesAllocable = calcMarketValueSharesInESOP * numberOfSharesAllocable;
        valueOfSharesAllocable = formatNumber(valueOfSharesAllocable); //B40

        var cashContributionAllocable;
        if (calcTotalComp > IRSCompLimit) {
            cashContributionAllocable = IRSCompLimit / calcTotalQualifiedComp * calcTotalCashContribution;
        } else {
            cashContributionAllocable = calcTotalComp / calcTotalQualifiedComp * calcTotalCashContribution;
        }
        cashContributionAllocable = formatNumber(cashContributionAllocable); //B39 //B43

        var valueOfShares = (calcMarketValueSharesInESOP * numberOfSharesAllocable);
        valueOfShares = formatNumberExact(valueOfShares); //B40

        var currentYearDividends = (calcDividendLevel * calcSharesHeldInESOP);
        currentYearDividends = formatNumber(currentYearDividends); //B44

        var cashAvailableForShareRepurchase = Number(calcOpeningCashBalance) + Number(cashContributionAllocable) + Number(currentYearDividends);
        cashAvailableForShareRepurchase = formatNumber(cashAvailableForShareRepurchase); //B45

        var sharesRepurchased;
        if ((calcMarketValueSharesInESOP * numberOfSharesAllocable) > cashAvailableForShareRepurchase) {
            sharesRepurchased = cashAvailableForShareRepurchase;
        } else {
            sharesRepurchased = valueOfShares;
        }
        sharesRepurchased = formatNumber(sharesRepurchased); //B46

        var endingCashBalanceBeforeReturn = (Number(cashAvailableForShareRepurchase) - Number(sharesRepurchased));
        endingCashBalanceBeforeReturn = formatNumber(endingCashBalanceBeforeReturn); //B47

        if (i == 0) {//special formula for the first year
            var investmemtReturn = (Number(calcOpeningCashBalance) + Number(currentYearDividends) * 0.5) * Number(providedAssumedPercentageIncreaseCash);
        } else {
            var investmemtReturn = (Number(calcEndingCashBalanceBeforeReturn) + Number(currentYearDividends) * 0.5) * Number(providedAssumedPercentageIncreaseCash);

        }
        investmemtReturn = formatNumber(investmemtReturn); //B48

        var endingCashBalance = Number(endingCashBalanceBeforeReturn) + Number(investmemtReturn);
        endingCashBalance = formatNumber(endingCashBalance); //B49

        var numberOfSharesPurchased = sharesRepurchased / calcMarketValueSharesInESOP;
        numberOfSharesPurchased = formatNumberExact(numberOfSharesPurchased); //B52

        var totalSharesHeldInESOP = Number(calcSharesHeldInESOP) + Number(numberOfSharesPurchased);
        totalSharesHeldInESOP = formatNumberExact(totalSharesHeldInESOP); //B53

        var annualValueOfSharesInESOP = (Number(totalSharesHeldInESOP) * Number(calcMarketValueSharesInESOP));
        annualValueOfSharesInESOP = formatNumber(annualValueOfSharesInESOP) //B54

        calcESOPAmount = formatNumber(Number(endingCashBalance) + Number(annualValueOfSharesInESOP)); //B55

        //end region

        data.push({
            age: calcAge,
            Retire: calcRetirementAmount,
            ESOP: calcESOPAmount
        });

        calcAge++
        calcTotalComp = (calcTotalComp * (1 + providedCompRate));
        calcMarketValueSharesInESOP = (calcMarketValueSharesInESOP * (1 + providedAssumedPercentageIncreaseShares));
        calcMarketValueSharesInESOP = formatNumberExact(calcMarketValueSharesInESOP);//B30
        calcDividendLevel = (calcDividendLevel * (1 + providedAssumedPercentageIncreaseDividends));
        calcDividendLevel = formatNumber(calcDividendLevel);// B32
        calcTotalQualifiedComp = (calcTotalQualifiedComp * (1 + InflationRateOfTotalQualifiedComp));
        calcTotalQualifiedComp = formatNumber(calcTotalQualifiedComp);//B34
        calcTotalCashContribution = (calcTotalCashContribution * (1 + InflationRateOfTotalAnnualCash));
        calcTotalCashContribution = formatNumber(calcTotalCashContribution);//B36
        calcOpeningCashBalance = endingCashBalance;//B42
        calcEndingCashBalanceBeforeReturn = endingCashBalanceBeforeReturn; //for formula use only, not aligned to an existing cell
        calcSharesHeldInESOP = totalSharesHeldInESOP;//B51

    }

    return { 'data': data, 'totalRetirement': calcRetirementAmount, 'totalESOP': calcESOPAmount, 'totalFunds': (Number(calcRetirementAmount) + Number(calcESOPAmount)) };
}