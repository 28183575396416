import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, FormFeedback, Form, Label, Input, Collapse, Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { HelpTextHover } from './Tooltip';
import ReactTooltip from 'react-tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';
import { formatWithCommas, getTooltipValue, getInputName } from '../utils/Format';

export class StartingDialog extends Component {
    constructor(props) {
        super(props);
        this.preStateChangeValidate = this.preStateChangeValidate.bind(this);
        this.state = {
            validated: false,
            currentAgeInvalid: false,
            retirementAgeInvalid: false,
            currentCompInvalid: false,
            compRateInvalid: false
        };
    }

    preStateChangeValidate(value, stateName) {
        if (stateName == "currentAge") {
            if (value >= 18 && value <= 99) {
                this.setState({ currentAgeInvalid: false });
            } else {
                this.setState({ currentAgeInvalid: true });
                return;
            }
        }

        if (stateName == "retirementAge") {
            if (value >= 18 && value <= 99) {
                this.setState({ retirementAgeInvalid: false });
            } else {
                this.setState({ retirementAgeInvalid: true });
                return;
            }
        }

        if (stateName == "currentComp") {
            if (value > 0) {
                this.setState({ currentCompInvalid: false });
            } else {
                this.setState({ currentCompInvalid: true });
                return;
            }
        }

        if (stateName == "compRate") {
            if (value > 0) {
                this.setState({ compRateInvalid: false });
            } else {
                this.setState({ compRateInvalid: true });
                return;
            }
        }

        if (!this.state.currentAgeInvalid && !this.state.currentCompInvalid && !this.state.retirementAgeInvalid && !this.state.compRateInvalid) {
            this.state.validated = true;
        } else {
            this.state.validated = false;
        }
    }

    render() {
        let audienceType = this.props.audience;
        let helpTextData = this.props.helpTextData;

        let CurrentCompTooltipText = getTooltipValue(helpTextData, audienceType, 1);
        let CompRateTooltipText = getTooltipValue(helpTextData, audienceType, 6);

        let inputData = this.props.inputData;

        let CurrentAgeName = getInputName(inputData, audienceType, 1);
        let RetirementAgeName = getInputName(inputData, audienceType, 2);
        let CurrentCompensationName = getInputName(inputData, audienceType, 3);
        let AnnualCompGrowthRateName = getInputName(inputData, audienceType, 4);

        return (
            <>
                <Modal autoFocus={false} isOpen={this.props.showModal} size="xl">
                    <ModalBody style={{ margin: "0", padding: "0px" }}>
                        <Row>
                            <Col xs={12} md={6} style={{ backgroundColor: "#eee" }}>
                                <img title="Aspire Logo" style={{ maxWidth: "100%" }} src="/Aspire-LogoDescriptor-Navy.png" />
                            </Col>
                            <Col className="g-4" xs={12} md={6}>
                                <Container className="mb-4">
                                    <Form noValidate validated="true" onSubmit={(evt) => this.props.handleModalSubmit(evt, this.state.validated)}>
                                        <Row>
                                            <Col>
                                                <Label for="currentAge">{CurrentAgeName} </Label>
                                                <Input invalid={this.state.currentAgeInvalid}
                                                    type="number"
                                                    autoFocus={true}
                                                    name="current_age"
                                                    onChange={(evt) => this.preStateChangeValidate(evt.target.value, "currentAge")} />
                                                <FormFeedback>Please enter a valid number.</FormFeedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label for="retirementAge">{RetirementAgeName} </Label>
                                                <Input invalid={this.state.retirementAgeInvalid}
                                                    type="number"
                                                    name="retirement_age"
                                                    onChange={(evt) => this.preStateChangeValidate(evt.target.value, "retirementAge")} />
                                                <FormFeedback>Please enter a valid number.</FormFeedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label for="currentComp">{CurrentCompensationName} </Label>
                                                <a data-tip data-for="CurrentComp"><HelpTextHover /></a>
                                                <ReactTooltip actTooltip id="CurrentComp" aria-haspopup="true">{CurrentCompTooltipText}</ReactTooltip>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text dollarPrepend">$</div>
                                                    </div>
                                                    <Input invalid={this.state.currentCompInvalid}
                                                        className="dollarPrependInput"
                                                        type="text" //this needs to be type=text to allow for formatting with commas
                                                        name="current_comp"
                                                        onBlur={(evt) => evt.target.value = formatWithCommas(evt.target.value)}
                                                        onChange={(evt) => this.preStateChangeValidate(evt.target.value, "currentComp")} />
                                                    <FormFeedback>Please enter a valid number.</FormFeedback>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label for="compRate">{AnnualCompGrowthRateName} </Label>
                                                <a data-tip data-for="CompRate"><HelpTextHover /></a>
                                                <ReactTooltip actTooltip id="CompRate" aria-haspopup="true">{CompRateTooltipText}</ReactTooltip>
                                                <div className="input-group">
                                                    <Input invalid={this.state.compRateInvalid}
                                                        className="percentAppendInput"
                                                        type="number"
                                                        name="comp_rate"
                                                        onBlur={(evt) => evt.target.value = formatWithCommas(evt.target.value)}
                                                        onChange={(evt) => this.preStateChangeValidate(evt.target.value, "compRate")} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text percentAppend">%</span>
                                                    </div>
                                                    <FormFeedback>Please enter a valid number.</FormFeedback>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col>
                                                <Button style={{ width: "100%", margin: "auto", backgroundColor: "#003865" }} type="Submit">Submit</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Container>
                            </Col>
                        </Row>
                        {/*<Container className="dialogBody">*/}
                        {/*    <Row>*/}
                        {/*        <Col xs={6} style={{ padding: 0, margin: 0, backgroundColor: "#eee", display: "flex", alignItems: "center", maxWidth: "2000px" }}>*/}
                        {/*            <img title="Aspire Logo" style={{ width: "100%", height: "75%" }} src="/Aspire-LogoDescriptor-Navy.png" />*/}
                        {/*        </Col>*/}
                        {/*        <Col xs={6} style={{ display: "flex", alignItems: "center", paddingLeft: "25px", paddingRight: "0" }}>*/}
                        {/*            <Form style={{ margin: "20px 0", width: "95%" }} noValidate validated="true" onSubmit={(evt) => this.props.handleModalSubmit(evt, this.state.validated)}>*/}
                        {/*                <Row>*/}
                        {/*                    <Col>*/}
                        {/*                        <Label for="currentAge">Current Age</Label>*/}
                        {/*                        <Input invalid={this.state.currentAgeInvalid}*/}
                        {/*                            type="number"*/}
                        {/*                            autoFocus={true}*/}
                        {/*                            name="current_age"*/}
                        {/*                            onChange={(evt) => this.preStateChangeValidate(evt.target.value, "currentAge")} />*/}
                        {/*                        <FormFeedback>Please enter a valid number.</FormFeedback>*/}
                        {/*                    </Col>*/}
                        {/*                </Row>*/}
                        {/*                <Row>*/}
                        {/*                    <Col>*/}
                        {/*                        <Label for="retirementAge">Retirement Age</Label>*/}
                        {/*                        <Input invalid={this.state.retirementAgeInvalid}*/}
                        {/*                            type="number"*/}
                        {/*                            name="retirement_age"*/}
                        {/*                            onChange={(evt) => this.preStateChangeValidate(evt.target.value, "retirementAge")} />*/}
                        {/*                        <FormFeedback>Please enter a valid number.</FormFeedback>*/}
                        {/*                    </Col>*/}
                        {/*                </Row>*/}
                        {/*                <Row>*/}
                        {/*                    <Col>*/}
                        {/*                        <Label for="currentComp">Current Compensation (Salary + Bonus) </Label>*/}
                        {/*                        <a data-tip data-for="CurrentComp"><HelpTextHover /></a>*/}
                        {/*                        <ReactTooltip actTooltip id="CurrentComp" aria-haspopup="true">{CurrentCompTooltipText}</ReactTooltip>*/}
                        {/*                        <div className="input-group">*/}
                        {/*                            <div className="input-group-prepend">*/}
                        {/*                                <div className="input-group-text dollarPrepend">$</div>*/}
                        {/*                            </div>*/}
                        {/*                            <Input invalid={this.state.currentCompInvalid}*/}
                        {/*                                className="dollarPrependInput"*/}
                        {/*                                type="text" //this needs to be type=text to allow for formatting with commas*/}
                        {/*                                name="current_comp"*/}
                        {/*                                onBlur={(evt) => evt.target.value = formatWithCommas(evt.target.value)}*/}
                        {/*                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "currentComp")} />*/}
                        {/*                            <FormFeedback>Please enter a valid number.</FormFeedback>*/}
                        {/*                        </div>*/}
                        {/*                    </Col>*/}
                        {/*                </Row>*/}
                        {/*                <Row>*/}
                        {/*                    <Col>*/}
                        {/*                        <Label for="compRate">Annual Comp Growth Rate</Label>*/}
                        {/*                        <a data-tip data-for="CompRate"><HelpTextHover /></a>*/}
                        {/*                        <ReactTooltip actTooltip id="CompRate" aria-haspopup="true">{CompRateTooltipText}</ReactTooltip>*/}
                        {/*                        <div className="input-group">*/}
                        {/*                            <Input invalid={this.state.compRateInvalid}*/}
                        {/*                                className="percentAppendInput"*/}
                        {/*                                type="number"*/}
                        {/*                                name="comp_rate"*/}
                        {/*                                onBlur={(evt) => evt.target.value = formatWithCommas(evt.target.value)}*/}
                        {/*                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "compRate")} />*/}
                        {/*                            <div className="input-group-append">*/}
                        {/*                                <span className="input-group-text percentAppend">%</span>*/}
                        {/*                            </div>*/}
                        {/*                            <FormFeedback>Please enter a valid number.</FormFeedback>*/}
                        {/*                        </div>*/}
                        {/*                    </Col>*/}
                        {/*                </Row>*/}
                        {/*                <Row className="mt-4">*/}
                        {/*                    <Col>*/}
                        {/*                        <Button style={{ width: "100%", margin: "auto", backgroundColor: "#003865" }} type="Submit">Submit</Button>*/}
                        {/*                    </Col>*/}
                        {/*                </Row>*/}
                        {/*            </Form>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</Container>*/}
                    </ModalBody>
                </Modal>
            </>
        );
    }
}
