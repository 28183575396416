import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import EditDefaults from './EditDefaults';
import { ChevronDoubleUp, ChevronDoubleDown } from 'react-bootstrap-icons';
import { Collapse, Button } from 'reactstrap';



function Defaults({ username }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalItemID, setModalItemID] = useState(null);
    const [ showSection, setShowSection ] = useState(false);

    useEffect(() => {
        getDefaults();
    }, []);

    function handleDefaultItemClick(itemID) {
        setModalItemID(itemID);
        setShowModal(true);
    };

    function getDefaults() {
        fetch('/api/DefaultItem')
            .then((response) => {
                return response.json();
            })
            .then((actualData) => {
                setData(actualData);
                setError(null);
            })
            .catch((err) => {
                setError(err.message);
                setData(null);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <EditDefaults getDefaults={getDefaults} setModalItemID={setModalItemID} showModal={showModal} itemID={modalItemID} setShowModal={setShowModal} username={username} />
            <div className="bg-light">
                <div className="sectionHeader adminHeader">
                    <div className="sectionHeaderWrapper">
                        <span>Defaults</span>
                        <span className="sectionHeaderTag">Employee Provided</span>
                    </div>
                    <Button onClick={()=> setShowSection(!showSection) } className='btn-toggle'>
                        {!showSection && <ChevronDoubleDown className="toggleImage" />}
                        {showSection && <ChevronDoubleUp className="toggleImage" />}
                        </Button></div>
                <Collapse isOpen={showSection}>
                    <div className="list-group">
                        {loading && <div>Loading...</div>}
                        {error && (
                            <div>{`There is a problem fetching the post data - ${error}`}</div>
                        )}
                        {data &&
                            data.map(({ id, name }) => (
                                <a key={id} onClick={() => handleDefaultItemClick(id)} className="list-group-item list-group-item-action">{name}</a>
                            ))}
                    </div>
                </Collapse>
            </div>
        </>
    )
}

export default Defaults;
