import React, { Component } from 'react';
import { FormFeedback, Row, Col, FormGroup, Form, Label, Input, Collapse, Button } from 'reactstrap';
import { HelpTextHover } from './Tooltip'
import ReactTooltip from 'react-tooltip';
//import { MaxCatchUpContribution } from '../constants/Global'
import 'bootstrap/dist/css/bootstrap.min.css';
import { PlusLg, DashLg, ChevronDoubleUp, ChevronDoubleDown } from 'react-bootstrap-icons';
import ReactSlider from 'react-slider';
import { formatWithCommas, getTooltipValue, getInputValue, getInputName } from '../utils/Format';

export class Inputs extends Component {
    constructor(props) {

        super(props);
        this.toggleSection = this.toggleSection.bind(this);
        this.preStateChangeValidate = this.preStateChangeValidate.bind(this);
        this.state = {
            ShowSectionPersonal: false,
            ShowSection401: true,
            ShowSectionESOP: true,
            currentAgeInvalid: false,
            retirementAgeInvalid: false,
            currentCompInvalid: false,
            compRateInvalid: false,
            catchUpAmountInvalid: false,
            current401Invalid: false,
            deferralPercentageInvalid: false,
            compRateInvalid: false,
            est401ReturnInvalid: false,
            beginningSharesHeldInvalid: false,
            assumedPercentageIncreaseSharesInvalid: false,
            assumedPercentageIncreaseDividendsInvalid: false,
            openingCashBalanceInvalid: false,
            assumedPercentageIncreaseCashInvalid: false,
        };
    }

    toggleSection(sectionName) {
        let NameOfShowState = "ShowSection" + sectionName;
        this.setState({
            [NameOfShowState]: !this.state[NameOfShowState]
        });
    }

    preStateChangeValidate(value, stateName) {
        if (stateName == "currentAge") {
            if (value >= 18 && value <= 99) {
                this.setState({ currentAgeInvalid: false });
                this.props.updateState(value, "currentAge", true); // this value is valid so recompute

            } else {
                this.setState({ currentAgeInvalid: true });
                this.props.updateState(value, "currentAge", false); //this value is not valid so don't recompute
            }
        }

        if (stateName == "retirementAge") {
            if (value >= 18 && value <= 99) {
                this.setState({ retirementAgeInvalid: false });
                this.props.updateState(value, "retirementAge", true);
            } else {
                this.setState({ retirementAgeInvalid: true });
                this.props.updateState(value, "retirementAge", false);
            }
        }

        if (stateName == "currentComp") {
            if (value > 0 && value != '') {
                this.setState({ currentCompInvalid: false });
                this.props.updateState(value, "currentComp", true);
            } else {
                this.setState({ currentCompInvalid: true });
                this.props.updateState(value, "currentComp", false);
            }
        }

        if (stateName == "compRate") {
            if (value >= 0 && value != '') {
                this.setState({ compRateInvalid: false });
                this.props.updateState(value, "compRate", true);
            } else {
                this.setState({ compRateInvalid: true });
                this.props.updateState(value, "compRate", false);
            }
        }

        if (stateName == "catchUpAmount") {
            if (value >= 0 && value != '' && value <= this.props.MaxCatchUpContribution) {
                this.setState({ catchUpAmountInvalid: false });
                this.props.updateState(value, "catchUpAmount", true);
            } else {
                this.setState({ catchUpAmountInvalid: true });
                this.props.updateState(value, "catchUpAmount", false);
            }
        }

        if (stateName == "current401") {
            if (value >= 0 && value != '') {
                this.setState({ current401Invalid: false });
                this.props.updateState(value, "current401", true);
            } else {
                this.setState({ current401Invalid: true });
                this.props.updateState(value, "current401", false);
            }
        }

        if (stateName == "deferralPercentage") {
            if (value >= 0 && value != '') {
                this.setState({ deferralPercentageInvalid: false });
                this.props.updateState(value, "deferralPercentage", true);
            } else {
                this.setState({ deferralPercentageInvalid: true });
                this.props.updateState(value, "deferralPercentage", false);
            }
        }

        if (stateName == "est401Return") {
            if (value >= 0 && value != '') {
                this.setState({ est401ReturnInvalid: false });
                this.props.updateState(value, "est401Return", true);
            } else {
                this.setState({ est401ReturnInvalid: true });
                this.props.updateState(value, "est401Return", false);
            }
        }

        if (stateName == "beginningSharesHeld") {
            if (value >= 0 && value != '') {
                this.setState({ beginningSharesHeldInvalid: false });
                this.props.updateState(value, "beginningSharesHeld", true);
            } else {
                this.setState({ beginningSharesHeldInvalid: true });
                this.props.updateState(value, "beginningSharesHeld", false);
            }
        }

        if (stateName == "assumedPercentageIncreaseShares") {
            if (value >= 0 && value != '') {
                this.setState({ assumedPercentageIncreaseSharesInvalid: false });
                this.props.updateState(value, "assumedPercentageIncreaseShares", true);
            } else {
                this.setState({ assumedPercentageIncreaseSharesInvalid: true });
                this.props.updateState(value, "assumedPercentageIncreaseShares", false);
            }
        }

        if (stateName == "assumedPercentageIncreaseDividends") {
            if (value >= 0 && value != '') {
                this.setState({ assumedPercentageIncreaseDividendsInvalid: false });
                this.props.updateState(value, "assumedPercentageIncreaseDividends", true);
            } else {
                this.setState({ assumedPercentageIncreaseDividendsInvalid: true });
                this.props.updateState(value, "assumedPercentageIncreaseDividends", false);
            }
        }

        if (stateName == "openingCashBalance") {
            if (value >= 0 && value != '') {
                this.setState({ openingCashBalanceInvalid: false });
                this.props.updateState(value, "openingCashBalance", true);
            } else {
                this.setState({ openingCashBalanceInvalid: true });
                this.props.updateState(value, "openingCashBalance", false);
            }
        }

        if (stateName == "assumedPercentageIncreaseCash") {
            if (value >= 0 && value != '') {
                this.setState({ assumedPercentageIncreaseCashInvalid: false });
                this.props.updateState(value, "assumedPercentageIncreaseCash", true);
            } else {
                this.setState({ assumedPercentageIncreaseCashInvalid: true });
                this.props.updateState(value, "assumedPercentageIncreaseCash", false);
            }
        }
    }

    render() {
        let audienceType = this.props.audience;
        let helpTextData = this.props.helpTextData;

        let CurrentCompTooltipText = getTooltipValue(helpTextData, audienceType, 1);
        let CompRateTooltipText = getTooltipValue(helpTextData, audienceType, 6);
        let DeferralTooltipText = getTooltipValue(helpTextData, audienceType, 7);
        let CatchUpTooltipText = getTooltipValue(helpTextData, audienceType, 8);
        let Current401TooltipText = getTooltipValue(helpTextData, audienceType, 9);
        let ShareIncreaseTooltipText = getTooltipValue(helpTextData, audienceType, 14);
        let PercentIncreaseTooltipText = getTooltipValue(helpTextData, audienceType, 15);
        let CurrentESOPTooltipText = getTooltipValue(helpTextData, audienceType, 17);
        let EstESOPReturnTooltipText = getTooltipValue(helpTextData, audienceType, 18);
        let BeginningSharesTooltipText = getTooltipValue(helpTextData, audienceType, 12);
        let Est401ReturnTooltipText = getTooltipValue(helpTextData, audienceType, 10);

        let inputData = this.props.inputData;

        let CurrentAgeVisible = getInputValue(inputData, audienceType, 1);
        let RetirementAgeVisible = getInputValue(inputData, audienceType, 2);
        let CurrentCompensationVisible = getInputValue(inputData, audienceType, 3);
        let AnnualCompGrowthRateVisible = getInputValue(inputData, audienceType, 4);
        let DeferralVisible = getInputValue(inputData, audienceType, 5);
        let AnnualCatchUpVisible = getInputValue(inputData, audienceType, 6);
        let Current401BalanceVisible = getInputValue(inputData, audienceType, 7);
        let Annual401ReturnVisible = getInputValue(inputData, audienceType, 8);
        let BeginningSharesESOPVisible = getInputValue(inputData, audienceType, 9);
        let AnnualSharePriceIncreaseVisible = getInputValue(inputData, audienceType, 10);
        let AnnualDividendIncreaseVisible = getInputValue(inputData, audienceType, 11);
        let CurrentESOPCashBalanceVisible = getInputValue(inputData, audienceType, 12);
        let ESOPCashReturnRateVisible = getInputValue(inputData, audienceType, 13);

        let CurrentAgeName = getInputName(inputData, audienceType, 1);
        let RetirementAgeName = getInputName(inputData, audienceType, 2);
        let CurrentCompensationName = getInputName(inputData, audienceType, 3);
        let AnnualCompGrowthRateName = getInputName(inputData, audienceType, 4);
        let DeferralName = getInputName(inputData, audienceType, 5);
        let AnnualCatchUpName = getInputName(inputData, audienceType, 6);
        let Current401BalanceName = getInputName(inputData, audienceType, 7);
        let Annual401ReturnName = getInputName(inputData, audienceType, 8);
        let BeginningSharesESOPName = getInputName(inputData, audienceType, 9);
        let AnnualSharePriceIncreaseName = getInputName(inputData, audienceType, 10);
        let AnnualDividendIncreaseName = getInputName(inputData, audienceType, 11);
        let CurrentESOPCashBalanceName = getInputName(inputData, audienceType, 12);
        let ESOPCashReturnRateName = getInputName(inputData, audienceType, 13);

        return (
            <>
                <Form className="calcForm bg-light">
                    <div className="sectionHeader"><span>Personal Information </span><Button onClick={() => this.toggleSection("Personal")} className='btn-toggle'>{this.state.ShowSectionPersonal == false ? <ChevronDoubleDown className="toggleImage" /> : <ChevronDoubleUp className="toggleImage" />}</Button></div>
                    <Collapse isOpen={this.state.ShowSectionPersonal}>
                        <FormGroup>
                            {CurrentAgeVisible && 
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputCurrentAge">
                                            {CurrentAgeName}
                                        </Label>
                                        <Input
                                            type="number"
                                            value={this.props.currentAge}
                                            invalid={this.state.currentAgeInvalid}
                                            onChange={(evt) => this.preStateChangeValidate(evt.target.value, "currentAge")} />
                                        <FormFeedback>Please enter a valid number.</FormFeedback>
                                    </Col>
                                    <Col>
                                        <ReactSlider
                                            onChange={(value) => this.props.updateState(value, "currentAge", true)}
                                            value={this.props.currentAge}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            max={99}
                                            min={18}
                                        />
                                    </Col>
                                </Row>
                            }
                            {RetirementAgeVisible && 
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputRetirementAge">
                                            {RetirementAgeName}
                                        </Label>
                                        <Input
                                            type="number"
                                            value={this.props.retirementAge}
                                            invalid={this.state.retirementAgeInvalid}
                                            onChange={(evt) => this.preStateChangeValidate(evt.target.value, "retirementAge")} />
                                        <FormFeedback>Please enter a valid number.</FormFeedback>
                                    </Col>
                                    <Col>
                                        <ReactSlider
                                            onChange={(value) => this.props.updateState(value, "retirementAge", true)}
                                            value={this.props.retirementAge}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            max={99}
                                            min={18}
                                        />
                                    </Col>
                                </Row>
                            }
                            {CurrentCompensationVisible &&
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputTotalComp">
                                            {CurrentCompensationName}
                                        </Label>
                                        <a data-tip data-for="CurrentComp"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="CurrentComp" aria-haspopup="true">{CurrentCompTooltipText}</ReactTooltip>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text dollarPrepend">$</div>
                                            </div>
                                            <Input
                                                type="text"
                                                className="dollarPrependInput"
                                                value={this.props.currentComp}
                                                invalid={this.state.currentCompInvalid}
                                                onBlur={(evt) => evt.target.value = formatWithCommas(evt.target.value)}
                                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "currentComp")}
                                            />
                                            <FormFeedback>Please enter a valid number.</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="upDownWrapper">
                                            <div className="arrowWrapper">
                                                <a title="Increase by $1,000" onClick={() => this.props.valueUpDown("up", "currentComp", 1000.00)}>
                                                    <PlusLg height={35} width={60} color="#ddd" />
                                                </a>
                                            </div>
                                            <div className="arrowWrapper">
                                                <a title="Decrease by $1,000" onClick={() => this.props.valueUpDown("down", "currentComp", 1000.00)}>
                                                    <DashLg height={35} width={60} color="#ddd" />
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {AnnualCompGrowthRateVisible && 
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputCompRate">
                                            {AnnualCompGrowthRateName}
                                        </Label>
                                        <a data-tip data-for="CompRate"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="CompRate" aria-haspopup="true">{CompRateTooltipText}</ReactTooltip>
                                        <div className="input-group">
                                            <Input step={1} className="percentAppendInput" type="number"
                                                value={this.props.compRate}
                                                invalid={this.state.compRateInvalid}
                                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "compRate")} />
                                            <div className="input-group-append">
                                                <span className="input-group-text percentAppend">%</span>
                                            </div>
                                            <FormFeedback>Please enter a valid number.</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col>
                                        <ReactSlider
                                            onChange={(value) => this.props.updateState(value, "compRate", true)}
                                            value={this.props.compRate}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            step={0.5}
                                            max={15}
                                            min={0}
                                        />
                                    </Col>
                                </Row>
                            }
                        </FormGroup>
                    </Collapse>
                </Form>
                <Form className="calcForm bg-light">
                    <div className="sectionHeader"><span>401(k) Information </span><Button onClick={() => this.toggleSection("401")} className='btn-toggle' >{this.state.ShowSection401 == false ? <ChevronDoubleDown className="toggleImage" /> : <ChevronDoubleUp className="toggleImage" />}</Button></div>
                    <Collapse isOpen={this.state.ShowSection401}>
                        <FormGroup>
                            {DeferralVisible &&
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputDeferral">
                                            {DeferralName}
                                        </Label>
                                        <a data-tip data-for="Deferral"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="Deferral" aria-haspopup="true">{DeferralTooltipText}</ReactTooltip>
                                        <div className="input-group">
                                            <Input step={1} type="number" className="percentAppendInput"
                                                value={this.props.deferralPercentage}
                                                invalid={this.state.deferralPercentageInvalid}
                                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "deferralPercentage")} />
                                            <div className="input-group-append">
                                                <span className="input-group-text percentAppend">%</span>
                                            </div>
                                            <FormFeedback>Please enter a valid number.</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col>
                                        <ReactSlider
                                            onChange={(value) => this.props.updateState(value, "deferralPercentage", true)}
                                            value={this.props.deferralPercentage}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            step={0.5}
                                            max={50}
                                            min={0}
                                        />
                                    </Col>
                                </Row>
                            }
                            {AnnualCatchUpVisible &&
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputCatchUp">
                                            {AnnualCatchUpName}
                                        </Label>
                                        <a data-tip data-for="CatchUp"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="CatchUp" aria-haspopup="true">{CatchUpTooltipText}</ReactTooltip>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text dollarPrepend">$</div>
                                            </div>
                                            <Input type="text"
                                                className="dollarPrependInput"
                                                value={this.props.catchUpAmount}
                                                invalid={this.state.catchUpAmountInvalid}
                                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "catchUpAmount")}
                                                onBlur={(evt) => evt.target.value = formatWithCommas(evt.target.value)} />
                                            <FormFeedback>Please enter a valid number.</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col>
                                        <ReactSlider
                                            onChange={(value) => this.props.updateState(value, "catchUpAmount", true)}
                                            value={this.props.catchUpAmount}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            max={this.props.MaxCatchUpContribution}
                                            min={0}
                                        />
                                    </Col>
                                </Row>
                            }
                            {Current401BalanceVisible &&
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputCurrent401">
                                            {Current401BalanceName}
                                        </Label>
                                        <a data-tip data-for="Current401"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="Current401" aria-haspopup="true">{Current401TooltipText}</ReactTooltip>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text dollarPrepend">$</div>
                                            </div>
                                            <Input type="text" className="dollarPrependInput"
                                                onBlur={(evt) => evt.target.value = formatWithCommas(evt.target.value)}
                                                value={this.props.current401}
                                                invalid={this.state.current401Invalid}
                                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "current401")} />
                                            <FormFeedback>Please enter a valid number.</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="upDownWrapper">
                                            <div className="arrowWrapper">
                                                <a title="Increase by $1,000" onClick={() => this.props.valueUpDown("up", "current401", 1000.00)}>
                                                    <PlusLg height={35} width={60} color="#ddd" />
                                                </a>
                                            </div>
                                            <div className="arrowWrapper">
                                                <a title="Decrease by $1,000" onClick={() => this.props.valueUpDown("down", "current401", 1000.00)}>
                                                    <DashLg height={35} width={60} color="#ddd" />
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {Annual401ReturnVisible &&
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputEst401Return">
                                            {Annual401ReturnName}
                                        </Label>
                                        <a data-tip data-for="Est401Return"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="Est401Return" aria-haspopup="true">{Est401ReturnTooltipText}</ReactTooltip>
                                        <div className="input-group">
                                            <Input type="number" className="percentAppendInput" value={this.props.est401Return}
                                                invalid={this.state.est401ReturnInvalid}
                                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "est401Return")} />
                                            <div className="input-group-append">
                                                <span className="input-group-text percentAppend">%</span>
                                            </div>
                                            <FormFeedback>Please enter a valid number.</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col>
                                        <ReactSlider
                                            onChange={(value) => this.props.updateState(value, "est401Return", true)}
                                            value={this.props.est401Return}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            step={0.5}
                                            max={15}
                                            min={0}
                                        />
                                    </Col>
                                </Row>
                            }
                        </FormGroup>
                    </Collapse>
                </Form>
                <Form className="calcForm bg-light">
                    <div className="sectionHeader"><span>ESOP Information </span><Button onClick={() => this.toggleSection("ESOP")} className='btn-toggle'>{this.state.ShowSectionESOP == false ? <ChevronDoubleDown className="toggleImage" /> : <ChevronDoubleUp className="toggleImage" />}</Button></div>
                    <Collapse isOpen={this.state.ShowSectionESOP}>
                        <FormGroup>
                            {BeginningSharesESOPVisible &&
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputBeginningShares">
                                            {BeginningSharesESOPName}
                                        </Label>
                                        <a data-tip data-for="BeginningShares"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="BeginningShares" aria-haspopup="true">{BeginningSharesTooltipText}</ReactTooltip>
                                        <Input type="number" value={this.props.beginningSharesHeld}
                                            invalid={this.state.beginningSharesHeldInvalid}
                                            onChange={(evt) => this.preStateChangeValidate(evt.target.value, "beginningSharesHeld")} />
                                        <FormFeedback>Please enter a valid number.</FormFeedback>
                                    </Col>
                                    <Col>
                                        <div className="upDownWrapper">
                                            <div className="arrowWrapper">
                                                <a title="Increase by 1" onClick={() => this.props.valueUpDown("up", "beginningSharesHeld", 1)}>
                                                    <PlusLg height={35} width={60} color="#ddd" />
                                                </a>
                                            </div>
                                            <div className="arrowWrapper">
                                                <a title="Decrease by 1" onClick={() => this.props.valueUpDown("down", "beginningSharesHeld", 1)}>
                                                    <DashLg height={35} width={60} color="#ddd" />
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {AnnualSharePriceIncreaseVisible &&
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputShareIncrease">
                                            {AnnualSharePriceIncreaseName}
                                        </Label>
                                        <a data-tip data-for="ShareIncrease"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="ShareIncrease" aria-haspopup="true">{ShareIncreaseTooltipText}</ReactTooltip>
                                        <div className="input-group">
                                            <Input type="number" className="percentAppendInput" value={this.props.assumedPercentageIncreaseShares}
                                                invalid={this.state.assumedPercentageIncreaseSharesInvalid}
                                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "assumedPercentageIncreaseShares")} />
                                            <div className="input-group-append">
                                                <span className="input-group-text percentAppend">%</span>
                                            </div>
                                            <FormFeedback>Please enter a valid number.</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col>
                                        <ReactSlider
                                            onChange={(value) => this.props.updateState(value, "assumedPercentageIncreaseShares", true)}
                                            value={this.props.assumedPercentageIncreaseShares}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            step={0.5}
                                            max={15}
                                            min={0}
                                        />
                                    </Col>
                                </Row>
                            }
                            {AnnualDividendIncreaseVisible &&
                                <Row>
                                <Col xs="8">
                                    <Label for="inputPercentIncrease">
                                            {AnnualDividendIncreaseName}
                                    </Label>
                                    <a data-tip data-for="PercentIncrease"><HelpTextHover /></a>
                                    <ReactTooltip actTooltip id="PercentIncrease" aria-haspopup="true">{PercentIncreaseTooltipText}</ReactTooltip>
                                    <div className="input-group">
                                        <Input type="number" className="percentAppendInput" value={this.props.assumedPercentageIncreaseDividends}
                                            invalid={this.state.assumedPercentageIncreaseDividendsInvalid}
                                            onChange={(evt) => this.preStateChangeValidate(evt.target.value, "assumedPercentageIncreaseDividends")} />
                                        <div className="input-group-append">
                                            <span className="input-group-text percentAppend">%</span>
                                        </div>
                                        <FormFeedback>Please enter a valid number.</FormFeedback>
                                    </div>
                                </Col>
                                <Col>
                                    <ReactSlider
                                        onChange={(value) => this.props.updateState(value, "assumedPercentageIncreaseDividends", true)}
                                        value={this.props.assumedPercentageIncreaseDividends}
                                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                        step={0.5}
                                        max={15}
                                        min={0}
                                    />
                                </Col>
                                </Row>
                            }
                            {CurrentESOPCashBalanceVisible &&
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputCurrentESOP">
                                            {CurrentESOPCashBalanceName}
                                        </Label>
                                        <a data-tip data-for="CurrentESOP"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="CurrentESOP" aria-haspopup="true">{CurrentESOPTooltipText}</ReactTooltip>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text dollarPrepend">$</div>
                                            </div>
                                            <Input type="text" className="dollarPrependInput"
                                                onBlur={(evt) => evt.target.value = formatWithCommas(evt.target.value)}
                                                value={this.props.openingCashBalance}
                                                invalid={this.state.openingCashBalanceInvalid}
                                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "openingCashBalance")} />
                                            <FormFeedback>Please enter a valid number.</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="upDownWrapper">
                                            <div className="arrowWrapper">
                                                <a title="Increase by $1,000" onClick={() => this.props.valueUpDown("up", "openingCashBalance", 1000.00)}>
                                                    <PlusLg height={35} width={60} color="#ddd" />
                                                </a>
                                            </div>
                                            <div className="arrowWrapper">
                                                <a title="Decrease by $1,000" onClick={() => this.props.valueUpDown("down", "openingCashBalance", 1000.00)}>
                                                    <DashLg height={35} width={60} color="#ddd" />
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {ESOPCashReturnRateVisible && 
                                <Row>
                                    <Col xs="8">
                                        <Label for="inputEstESOPReturn">
                                            {ESOPCashReturnRateName}
                                        </Label>
                                        <a data-tip data-for="EstESOPReturn"><HelpTextHover /></a>
                                        <ReactTooltip actTooltip id="EstESOPReturn" aria-haspopup="true">{EstESOPReturnTooltipText}</ReactTooltip>
                                        <div className="input-group">
                                            <Input type="number" className="percentAppendInput" value={this.props.assumedPercentageIncreaseCash}
                                                invalid={this.state.assumedPercentageIncreaseCashInvalid}
                                                onChange={(evt) => this.preStateChangeValidate(evt.target.value, "assumedPercentageIncreaseCash")} />
                                            <div className="input-group-append">
                                                <span className="input-group-text percentAppend">%</span>
                                            </div>
                                            <FormFeedback>Please enter a valid number.</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col>
                                        <ReactSlider
                                            onChange={(value) => this.props.updateState(value, "assumedPercentageIncreaseCash", true)}
                                            value={this.props.assumedPercentageIncreaseCash}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            step={0.5}
                                            max={15}
                                            min={0}
                                        />
                                    </Col>
                                </Row>
                            }
                        </FormGroup>
                    </Collapse>
                </Form>
            </>
        );
    }
}
