import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function Unauthorized() {
    return (
        <Container className="m-20">
            <div>Session expired. Please close your browser and try again.</div>
        </Container>
    );
}