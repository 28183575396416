import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.css';
import { Home } from "./pages/Home";
import Admin from "./pages/Admin";
import Unauthorized from "./pages/Unauthorized";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { MsalProvider } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { appRoles } from './authConfig';


function App({ pca, audience }) {
    const [userDisplayName, setUserDisplayName] = useState(null);

    if (audience == "Unauthorized") {
        return (
            <Unauthorized />
        );
    } else if (audience == "External") {
        return (
            <Layout>
                <Pages audience={audience} username={userDisplayName} />
            </Layout>
        );

    } else if (audience == "Internal") {
        {
            pca.handleRedirectPromise()
                .then((tokenResponse) => {
                    console.log(tokenResponse);
                    if (!tokenResponse) {
                        const accounts = pca.getAllAccounts();
                        if (accounts.length === 0) {
                            // No user signed in
                            pca.loginRedirect();
                        } else {
                            console.log(accounts[0].idTokenClaims);
                            setUserDisplayName(accounts[0].name.toString())
                        }
                    }
                })
                .catch(err => {
                    // Handle error
                    console.error(err);
                });

            return (
                <ClientSideNavigation pca={pca} audience={audience}>
                    <MsalProvider instance={pca}>
                        <AuthenticatedTemplate>
                            <Layout>
                                <Pages audience={audience} username={userDisplayName} />
                            </Layout>
                        </AuthenticatedTemplate>
                    </MsalProvider>
                </ClientSideNavigation>
            );
        }
    }
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
function ClientSideNavigation({ pca, children }) {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        setFirstRender(false);
    }, []);

    if (firstRender) {
        return null;
    }

    return children;
}

function Pages(props) {
    return (
        <Routes>
            <Route path="/" element={<Home audience={props.audience} />} />
            <Route path="/admin" element={<Admin username={props.username} audience={props.audience} />} />
        </Routes>
    )
}

export default App;