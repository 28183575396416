import React, { Component } from 'react';
import { Inputs } from '../components/Inputs';
import { StartingDialog } from '../components/StartingDialog';
import { Graph } from '../components/Graph';
import { formatToNumber, formatWithCommas, getTooltipValue } from '../utils/Format';
import { computeData } from '../utils/Computations';
import { Container, Row, Col } from 'reactstrap';
import { BarChart } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

export class Home extends Component {
    constructor(props) {
        super(props);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
        this.updateState = this.updateState.bind(this);
        this.reCompute = this.reCompute.bind(this);
        this.valueUpDown = this.valueUpDown.bind(this);
        this.state = {
            currentAge: 0,
            retirementAge: 0,
            currentComp: 0,
            compRate: 0,
            deferralPercentage: 0,
            catchUpAmount: 0,
            est401Return: 0,
            current401: 0,
            beginningSharesHeld: 0,
            assumedPercentageIncreaseShares: 0,
            assumedPercentageIncreaseDividends: 0,
            assumedPercentageIncreaseCash: 0,
            openingCashBalance: 0,
            totalRetirement: 0,
            totalESOP: 0,
            totalFunds: 0,
            StartingDividendLevel: 0,
            MaxContributionPercentage: 0,
            MinimumCompLevel: 0,
            IRSCompLimit: 0,
            MaxAnnualContribution: 0,
            MaxCompanyMatch: 0,
            CompanyMatchSubjectToMatch: 0,
            MaxCatchUpContribution: 0,
            AssumedQualifiedCompensation: 0,
            AssumedSharesAvailable: 0,
            TotalAnnualCashContribution: 0,
            InflationRateOfTotalQualifiedComp: 0,
            InflationRateOfTotalAnnualCash: 0,
            CurrentMarketValueOfShares: 0,
            chartData: [],
            showModal: true,
            showGraph: false,
            isLoading: true,
            error: null,
            helpTextData: null,
            defaultItemData: null,
            staticItemData: null,
            inputData: null
        };
    }

    handleModalSubmit(event, isValid) {
        event.preventDefault();

        if (isValid) {
            let compFormatted = formatToNumber(event.target.current_comp.value);//remove the commas that were added

            //set the values from the dialog into state
            this.setState({
                currentAge: event.target.current_age.value,
                retirementAge: event.target.retirement_age.value,
                currentComp: compFormatted,
                compRate: event.target.comp_rate.value,
                showModal: false
            });

            //wait for the state change to take effect
            setTimeout(() => {
                let computations = computeData(this.state);

                let computedData = computations.data;
                let totalRetirement = computations.totalRetirement;
                let totalESOP = computations.totalESOP;
                let totalFunds = computations.totalFunds;

                this.setState({
                    showGraph: true,
                    chartData: computedData,
                    totalRetirement: totalRetirement,
                    totalESOP: totalESOP,
                    totalFunds: totalFunds
                });
            }, 1500);

        }
    }

    updateState(value, stateToUpdate, reCompute) {
        if (reCompute) {
            this.setState({
                [stateToUpdate]: value,
            });

            setTimeout(
                function () {
                    this.reCompute();
                }
                    .bind(this),
                1000
            );

        } else {
            this.setState({
                [stateToUpdate]: value
            });
        }
    }

    valueUpDown(direction, stateToUpdate, incremement) {
        var startingValue = formatToNumber(this.state[stateToUpdate]);
        if (direction == "up") {
            var value = formatWithCommas(startingValue + incremement);
            this.updateState(value, stateToUpdate, true);
        } else {
            var value = formatWithCommas(((startingValue - incremement) > 0 ? (startingValue - incremement) : 0));
            this.updateState(value, stateToUpdate, true)
        }
    }

    reCompute() {
        let computations = computeData(this.state);

        this.setState({
            chartData: computations.data,
            totalESOP: computations.totalESOP,
            totalRetirement: computations.totalRetirement,
            totalFunds: computations.totalFunds
        })
    }

    componentDidMount() {
        let audienceType = this.props.audience;

        fetch(`/api/HelpText`)
            .then((response) => {
                return response.json();
            })
            .then((responseData) => {
                this.setState({ helpTextData : responseData })
                this.setState({ error: null });
            })
            .catch((err) => {
                this.setState({ error: err.message });
                this.setState({ helpTextData: null })
            })
            .finally(() => {
                fetch(`/api/DefaultItem`)
                    .then((response) => {
                        return response.json();
                    })
                    .then((responseData) => {
                        this.setState({ deferralPercentage: getTooltipValue(responseData, audienceType, 2) })
                        this.setState({ est401Return: getTooltipValue(responseData, audienceType, 3) })
                        this.setState({ assumedPercentageIncreaseShares: getTooltipValue(responseData, audienceType, 4) })
                        this.setState({ assumedPercentageIncreaseDividends: getTooltipValue(responseData, audienceType, 6) })
                        this.setState({ assumedPercentageIncreaseCash: getTooltipValue(responseData, audienceType, 7) })
                        this.setState({ error: null });
                    })
                    .catch((err) => {
                        this.setState({ error: err.message });
                        this.setState({ defaultItemData: null })
                    })
                    .finally(() => {
                        fetch(`/api/StaticValue`)
                            .then((response => {
                                return response.json();
                            }))
                            .then((responseData) => {
                                this.setState({ StartingDividendLevel: getTooltipValue(responseData, audienceType, 15) })
                                this.setState({ MaxContributionPercentage: getTooltipValue(responseData, audienceType, 1) })
                                this.setState({ MinimumCompLevel: getTooltipValue(responseData, audienceType, 3) })
                                this.setState({ IRSCompLimit: getTooltipValue(responseData, audienceType, 4) })
                                this.setState({ MaxAnnualContribution: getTooltipValue(responseData, audienceType, 5) })
                                this.setState({ MaxCompanyMatch: getTooltipValue(responseData, audienceType, 6) })
                                this.setState({ CompanyMatchSubjectToMatch: getTooltipValue(responseData, audienceType, 7) })
                                this.setState({ MaxCatchUpContribution: getTooltipValue(responseData, audienceType, 8) })
                                this.setState({ AssumedQualifiedCompensation: getTooltipValue(responseData, audienceType, 10) })
                                this.setState({ AssumedSharesAvailable: getTooltipValue(responseData, audienceType, 9) })
                                this.setState({ TotalAnnualCashContribution: getTooltipValue(responseData, audienceType, 12) })
                                this.setState({ InflationRateOfTotalQualifiedComp: getTooltipValue(responseData, audienceType, 11) })
                                this.setState({ InflationRateOfTotalAnnualCash: getTooltipValue(responseData, audienceType, 13) })
                                this.setState({ CurrentMarketValueOfShares: getTooltipValue(responseData, audienceType, 14) })
                            })
                            .catch((err) => {
                                this.setState({ error: err.message });
                                this.setState({ defaultItemData: null })
                            })
                            .finally(() => {
                                fetch(`/api/Inputs`)
                                    .then((response) => {
                                        return response.json();
                                    })
                                    .then((responseData) => {
                                        this.setState({ inputData: responseData })
                                        this.setState({ error: null });
                                    })
                                    .catch((err) => {
                                        this.setState({ error: err.message });
                                        this.setState({ defaultItemData: null })
                                    })
                                    .finally(() => {
                                        this.setState({ isLoading: false });
                                    })
                            })
                    });
            });
    }

    render() {
        return (
            <div id="aspireWrapper">
                {this.state.isLoading && <div>Loading...</div>}
                {this.state.error && (
                    <div>{`There is a problem fetching the post data - ${this.state.error}`}</div>
                )}
                {this.state.helpTextData && !this.state.isLoading &&
                    <>
                    <StartingDialog
                        currentAge={this.state.currentAge}
                        retirementAge={this.state.retirementAge}
                        currentComp={this.state.currentComp}
                        compRate={this.state.compRate}
                        handleModalSubmit={this.handleModalSubmit}
                        showModal={this.state.showModal}
                        updateState={this.updateState}
                        helpTextData={this.state.helpTextData}
                        audience={this.props.audience}
                        inputData={this.state.inputData}
                    />
                    <Container>
                        <Row id="graphWrapper">
                            <Col md={7} xs={12}>
                                {this.state.showGraph ?
                                    <Graph audience={this.props.audience} helpTextData={this.state.helpTextData} retirementTotal={this.state.totalRetirement} ESOPTotal={this.state.totalESOP} chartData={this.state.chartData} fundsTotal={this.state.totalFunds} />
                                    :
                                    <Row>
                                        <Col>
                                            <div id="graphImageWrapper">
                                                <BarChart id="graphImage"></BarChart>
                                            </div>
                                        </Col>
                                    </Row>}
                            </Col>
                            <Col md={5} xs={12}>
                                <Container className="g-0">
                                    <Row>
                                        <Col>
                                            <Inputs
                                                currentAge={this.state.currentAge}
                                                retirementAge={this.state.retirementAge}
                                                currentComp={this.state.currentComp}
                                                compRate={this.state.compRate}
                                                updateState={this.updateState}
                                                deferralPercentage={this.state.deferralPercentage}
                                                catchUpAmount={this.state.catchUpAmount}
                                                est401Return={this.state.est401Return}
                                                current401={this.state.current401}
                                                assumedPercentageIncreaseShares={this.state.assumedPercentageIncreaseShares}
                                                assumedPercentageIncreaseDividends={this.state.assumedPercentageIncreaseDividends}
                                                assumedPercentageIncreaseCash={this.state.assumedPercentageIncreaseCash}
                                                beginningSharesHeld={this.state.beginningSharesHeld}
                                                openingCashBalance={this.state.openingCashBalance}
                                                valueUpDown={this.valueUpDown}
                                                helpTextData={this.state.helpTextData}
                                                audience={this.props.audience}
                                                inputData={this.state.inputData}
                                                MaxCatchUpContribution={this.state.MaxCatchUpContribution}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-5 mb-5 text-center font-weight-light footer">
                                The calculations and returns set forth above are based upon assumptions and estimated rates of return.
                                <br />Burns & McDonnell does not guarantee the financial results cited in this calculation.
                                <br /><br />Powered by <img title="Burns & McDonnell Logo" src="/BMcD-Logo.png" />
                            </Col>
                        </Row>
                    </Container>
                    </>
                }
            </div>


        );
    }
}


