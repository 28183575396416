import React, { Component } from 'react';
import { Collapse, Nav, Row, Col, Navbar, NavbarBrand, Container, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header >
                <Navbar style={{ height: "9rem" }} className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3" container light>
                    <Row style={{ height: "8rem", display: "flex" }}>
                        <Col xs={8} style={{ height: "8rem", alignItems: "center", display: "flex", paddingLeft: "2rem" }}>
                            <NavLink tag={Link} className="text-dark" to="/">
                                <img title="Aspire Description" className="navTitle" src="/Aspire-Description.png" />
                            </NavLink>
                        </Col>
                        <Col xs={4} style={{ height: "8rem", alignItems: "center", display: "flex" }}>
                            <div style={{ textAlign: "right", width: "100%" }}>
                                <img title="Aspire Logo" className="navIcon" src="/Aspire-Logo-White.png" />
                            </div>
                        </Col>
                    </Row>
                    {/*<NavbarToggler onClick={this.toggleNavbar} className="mr-2" />*/}
                    {/*<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>*/}
                    {/*  <ul className="navbar-nav flex-grow">*/}
                    {/*    <NavItem>*/}
                    {/*      <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
                    {/*    </NavItem>*/}
                    {/*  </ul>*/}
                    {/*</Collapse>*/}
                </Navbar>
            </header>
        );
    }
}
